@use "src/styles/common/variables";

.help-form {
	margin-top: 20px;
	display: flex;
	flex-direction: column;
	gap: 15px;
}

.form-label {
	font-size: 14px;
	color: white;
}

.form-input {
	padding: 10px;
	// border: 1px solid #ccc;
	border-radius: 4px;
	font-size: 14px;
	width: 100%;
}

.success-message {
	color: variables.$success-color;
	text-align: center;
	margin: 0;
	margin-top: 5px;
}

.bigger {
	min-height: 30vh;
}

.form-input:hover {
	// padding: 10px;
	border: 1px solid #ccc;
	// border-radius: 4px;
	// font-size: 14px;
	// width: 100%;
}

.form-submit {
	background-color: #00cccc;
	color: black;
	border: none;
	padding: 10px 15px;
	border-radius: 4px;
	cursor: pointer;
	font-size: 14px;
	transition: background-color 0.3s ease;
}

.form-submit:hover {
	background-color: #009999;
}

.ticket-box {
	border: 1px solid #ccc;
	border-radius: 8px;
	padding: 10px;
	display: inline-flex;
	width: 100%;
	text-align: center;
	vertical-align: top;
	background-color: rgba(8, 8, 8, 0.9);
}

.ticket-title {
	font-size: 1.2em;
	margin-bottom: 10px;
	color: black;
}

.ticket-description {
	font-size: 0.9em;
	color: #555;
}

.ticket-time {
	font-size: 0.8em;
	color: #888;
}
