@use "src/styles/common/variables";

.subscription-dashboard {
  .subscription-dashboard-buttons {
    .subscription-dashboard-cancel-button {
      margin-bottom: 15px;
    }
  }

  .subscription-dashboard-modal-confirm-button {
    margin-bottom: 15px;
  }

  .subscription-dashboard-months-label {
    margin: 0;
  }

  .subscription-dashboard-months {
    font-size: variables.$font-size-l;
  }

  .subscription-dashboard-status {
    margin-bottom: 15px;

    .subscription-dashboard-status-title {
      margin: 0;
    }
  }

  .subscription-dashboard-recurring-amount-label {
    margin: 0;
  }
}

.subscribe-to-plan {
  // width: 100%;
  h2 {
    margin-bottom: variables.$spacing-xs;
  }

  .subscribe-to-plan-wrap-plan {
    margin-bottom: variables.$spacing-xs;
  }
}

// .subscriptionClass {
//   width: 100%; // Adjust width as needed
//   padding: 10px;
//   border: 1px solid grey;
//   border-radius: 5px;
//   cursor: pointer;
//   font-size: 16px; // Adjust font size as needed

//   option {
//     padding: 10px;
//     background-color: white;
//     color: black;
//   }

//   option:selected {
//     background-color: lightgrey;
//   }

//   &:hover {
//     border-color: darkgrey;
//   }
// }


.firstSpan {
  background-color: grey;
  // color: black;
  padding: 10px;
  width: 50%;
}



.thirdSpan {
  background-color: white;
  padding-right: 10px;
  padding: 10px;
  width: 70%;
}

.selected {
  border: 2px solid #00cccc;
}

.tokenOption {
  display: flex;
  flex-direction: row;
  color: black;
  // height: 50px;
  width: 138%;
  margin: 0px 0px 10px 0px;
  font-weight: 600;
  cursor: pointer;
}


.cart-page-payment-methods {
  margin-bottom: 32px;
  margin-top: 32px;

  h3 {
    margin-bottom: 15px;
  }

  .cart-page-select-payment-method {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 11px;
    border: solid;
    border-color: transparent;

    &.--selected {
      padding: 11px;
      border-color: variables.$primary-color;
    }
    .cart-page-select-button {
      background-color: transparent;
      cursor: pointer;
      border: none;
      color: variables.$primary-color;
    }
  }
}

.cart-page-add-new-credit-card {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 6px;
  border: none;
  border-color: variables.$primary-color;
}

.cart-page-details {
  font-size: variables.$font-size-xs;
  margin-bottom: 0px;
  text-align: center;
  margin-top: 5px;
}

.cart-button {
  margin-bottom: 15px;
}

.cart-page-checkout-error {
  color: variables.$error-color;
  margin-bottom: 0px;
}

.cart-page-wrap-exit-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
  text-align: center;

  a {
    margin: auto;
  }
}

.cart-single-product {
  display: flex;
  justify-content: space-between;
  position: relative;

  .cart-single-product-remove {
    width: 16px;
    font-size: variables.$font-size-s;
    cursor: pointer;
  }

  .cart-section-wrapper {
    border-top: solid 1px;

    .cart-single-product-subtext {
      font-size: variables.$font-size-xs;
    }
  }
}

.cart-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: variables.$spacing-l;

  .cart-card {
    padding: 0px variables.$spacing-xs;
    width: 470px;
    color: variables.$primary-color;
    padding: 44px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .cart-total {
      margin-top: 5px;
      padding-top: 5px;
      border-top: 1px solid #fff;
      display: flex;
      justify-content: space-between;
    }

    .cart-button {
      background-color: variables.$button-color;
      color: variables.$background-color;
      cursor: pointer;
      border: none;
      width: 100%;
      font-size: variables.$font-size-s;
      padding: variables.$spacing-xs;

      &.--disabled {
        background-color: variables.$secondary-color;
        cursor: not-allowed;
      }
    }
  }
}

.cart-page-checkout-button {
  margin-bottom: variables.$spacing-xs;
}

.cart-page-table {
  margin-bottom: 15px;

  .cart-page-table-total {
    td {
      padding-top: 15px;
    }
  }

  .cart-page-last-product {
    td {
      padding-bottom: 15px;
      border-bottom: 2px solid variables.$secondary-color;
    }
  }

  .cart-page-remove-product {
    text-align: center;
    padding-left: 15px;

    .cart-page-remove-product-x {
      cursor: pointer;
    }
  }

  .cart-page-product-price {
    text-align: right;
  }
}

.cart-page-destinations {
  display: flex;
  flex-direction: column;

  .cart-page-destinations-choices {
    margin-bottom: 15px;

    .cart-page-destinations-choice {
      margin: 5px 0px;
      cursor: pointer;
      padding: variables.$spacing-xs 15px;

      &.--selected {
        border: 2px solid variables.$button-color;
      }

      &:not(.--selected) {
        border: 2px solid transparent;
      }
    }
  }
}

.cart-page-email-input {
  background-color: variables.$primary-color;
  color: variables.$background-color;

  &:hover::placeholder {
    color: variables.$background-color;
  }
}

.cart-page-add-new-payment-link {
  margin: 15px;
  cursor: pointer;
  text-decoration: underline;
}

.cart-card {
  background-color: rgba(variables.$background-color, 0.75);
}
