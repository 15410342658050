.extra-signin-methods {
    width: 100%;
    height: 100%;
    margin-top: 20px;
}

.kep-login-facebook {

    width: 100% !important;
    height: 40px !important;
    border-radius: 4px !important;
    padding: calc(.34435vw + 5.38843px) calc(.34435vw + 18.38843px) !important;
}